<!--
 * @Description: 首页基础信息
 * @Author: xiawenlong
 * @Date: 2021-04-22 19:36:54
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-29 00:33:04
-->
<template>
  <div class="card info">
    <div class="info">
      <div class="logo">
        <img :src="collegeInfo.logo" alt="" />
      </div>
      <div class="text">
        <div class="name">{{ collegeInfo.organizationName }}</div>
        <div class="intro">
          {{ collegeInfo.introduction }}
        </div>
      </div>
    </div>
    <!-- <ul class="data">
      <li v-for="item in dataSource" :key="item.name">
        <div>
          <img :src="item.icon" alt="" />
          <p>{{ item.name }}</p>
        </div>
        <countTo :start-val="0" :end-val="item.num" :duration="countDuration"></countTo>
      </li>
    </ul> -->
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')
// import countTo from 'vue-count-to'
export default {
  name: 'DashboardInfoCard',
  // components: { countTo },
  data() {
    return {
      countDuration: 1600,
      dataSource: [
        { name: '学堂总人数', num: 1556, icon: require('@/assets/common/home_people_icon.png') },
        { name: '在线人数', num: 456, icon: require('@/assets/common/home_online_icon.png') },
        { name: '完成培训班级数', num: 18, icon: require('@/assets/common/home_class_icon.png') },
        { name: '正在培训班级数', num: 88, icon: require('@/assets/common/home_studing_icon.png') },
        { name: '总完成学时', num: 384223, icon: require('@/assets/common/home_time_icon.png') },
      ],
    }
  },
  computed: {
    ...mapState(['collegeInfo']),
  },
}
</script>
<style lang="scss" scoped>
.card {
  &.info {
    width: 100%;
    padding: 20px;
    .info {
      display: flex;
      margin-bottom: 20px;
      .logo {
        width: 120px;
        height: 120px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        img {
          @extend %width-height;
        }
      }
      .text {
        flex: 1;
        padding-left: 18px;
        .name {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          padding-top: 10px;
          margin-bottom: 6px;
        }
        .intro {
          width: 630px;
          font-size: 14px;
          font-weight: 400;
          color: #606266;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 158px;
      li {
        text-align: center;
        > div {
          display: flex;
          p {
            font-size: 14px;
            color: #666666;
            padding-top: 2px;
          }
          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }
        > span {
          font-size: 30px;
          color: #333333;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
